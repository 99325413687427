<template>
	<div id="mapTest">
	</div>
</template>

<script>
	import {
		getIp,
		getMapFun
	} from '@/request/api.js';
	import axios from 'axios';
	export default {
		data() {
			return {
				mapKey: 'SZSBZ-PNEKW-YH2RV-36AI5-JJPJJ-4RFMJ'
			}
		},
		created() {
			this.getLocation();
		},
		mounted() {
			this.initMap()
		},
		methods: {
			// 获取当前定位
			getLocation() {
				// getIp().then(res => {
				// 	console.log(res)
				// })

				getMapFun({
					key: this.mapKey
				}).then(res => {
					console.log(JSON.parse(res.data))
				})

				// let data = {
				// 	key: this.mapKey,
				// }
				// getMapFun('//apis.map.qq.com/ws/location/v1/ip', data).then(res => {
				// 	console.log(res)
				// })
				// 
				// axios({
				// 	method: 'get', // 或者 'post' 等其他HTTP方法
				// 	url: `https://apis.map.qq.com/ws/location/v1/ip?key=${this.mapKey}`, // 你的API端点
				// 	headers: {
				// 		'Access-Control-Allow-Origin': window.location.origin
				// 	}
				// }).then(res => {
				// 	// 处理响应数据
				// 	console.log(res);
				// }).catch(err => {
				// 	// 处理错误
				// 	console.error(err);
				// });
			},

			initMap() {
				//定义地图中心点坐标
				var center = new TMap.LatLng(39.984120, 116.307484)
				//定义map变量，调用 TMap.Map() 构造函数创建地图
				var map = new TMap.Map(document.getElementById('mapTest'), {
					center: center, //设置地图中心点坐标
					zoom: 17.2, //设置地图缩放级别
					pitch: 0, //设置俯仰角
					rotation: 0 //设置地图旋转角度
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	#mapTest {
		width: 100%;
		height: 100vh;
		background-color: #ccc !important;
	}
</style>